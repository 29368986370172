import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import FullScreenSection from "../components/full-screen-section"
import Seo from "../components/seo"

import styled from 'styled-components'

import loadable from '@loadable/component'

// import * as AFRAME from 'aframe';

// import { Helmet } from 'react-helmet'

// import 'aframe-particle-system-component';

import { Suspense , useRef, useState, useMemo, useLayoutEffect, useEffect, Fragment } from 'react';


// import * as TWEEN from "@tweenjs/tween.js";

// const AFRAME = loadable.lib(() => import('aframe'));

// if (typeof window !== `undefined`) {
//   const AFRAME = require('aframe');
// }


const VrPage = () => {

const AframeScene = () => {

  const status = useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/aframe/1.2.0/aframe.min.js"
  );  

  function playVideoOnScroll(element) {

      var vid = element; // select video element
      var playbackConst = 3; // lower numbers = slower playback
      var startingYOffset = element.offsetTop - window.innerHeight;
      vid.pause(); // pause video on load
      
      // pause video on document scroll (stops autoplay once scroll started)
      window.onscroll = function(){
          vid.pause();
      };
      
      // refresh video frames on interval for smoother playback
      setInterval(function(){
          vid.currentTime = (window.pageYOffset-startingYOffset)/(window.innerHeight/playbackConst);
      }, 40);
  }  


  useEffect(() => {

    if (status == 'ready') {

      // if (document.querySelector('[play-on-click]').attached == false || document.querySelector('[play-on-click]').attached == undefined) {
        /* global AFRAME */
        // AFRAME.registerComponent('play-on-click', {
        //   init: function () {
        //     this.onClick = this.onClick.bind(this);
        //   },
        //   play: function () {
        //     window.addEventListener('click', this.onClick);
        //   },
        //   pause: function () {
        //     window.removeEventListener('click', this.onClick);
        //   },
        //   onClick: function (evt) {
        //     console.dir('click')
        //     var videoEl = this.el.getAttribute('material').src;
        //     if (!videoEl) { return; }
        //     this.el.object3D.visible = true;
        //     videoEl.play();
        //   }
        // });

      // }

    }

    // window.externalLibrary.method()

    // console.dir(document.querySelector('[play-on-click]').attached == false || document.querySelector('[play-on-click]').attached == undefined);

      // console.dir(AFRAME);

        // if (document.querySelector('[play-on-click]').attached == false || document.querySelector('[play-on-click]').attached == undefined) {

          // if (AFRAME != undefined) {
            /* global AFRAME */
            // AFRAME.registerComponent('play-on-click', {
            //   init: function () {
            //     this.onClick = this.onClick.bind(this);
            //   },
            //   play: function () {
            //     window.addEventListener('click', this.onClick);
            //   },
            //   pause: function () {
            //     window.removeEventListener('click', this.onClick);
            //   },
            //   onClick: function (evt) {
            //     console.dir('click')
            //     var videoEl = this.el.getAttribute('material').src;
            //     if (!videoEl) { return; }
            //     this.el.object3D.visible = true;
            //     videoEl.play();
            //   }
            // });              
          // } else {
          //   console.log('AFRAME NOT LOADED');
          // }

        // }
       
  }, [status])

  return  <Fragment>

            {status === "ready" && (
                <a-scene loading-screen="dotsColor: gray; backgroundColor: black">
                    <a-assets>
                      <video id="video"
                             loop
                             autoPlay
                             playsInline
                             preload
                             src="/cdll_vr_2160.mp4">
                      </video>
                    </a-assets>

                    <a-videosphere
                      rotation="0 -90 0" src="#video"
                      play-on-click>
                    </a-videosphere>

                    <a-camera>
                      <a-entity
                        position="0 0 -1.5"
                        hide-on-play="#video">
                      </a-entity>
                    </a-camera>
                  </a-scene>
            )}

          </Fragment>
}


  const data = useStaticQuery(
    graphql`
      query {
        bg1:

        file(relativePath: { eq: "pointcloud.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

        bg2:
        file(relativePath: { eq: "obj.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }

      }
    `
  )

  const bg1 = data.bg1.childImageSharp.fluid
  const bg2 = data.bg2.childImageSharp.fluid

  // playVideoOnScroll(document.querySelector('video'));

  const playVideo = () => {
    if (typeof window !== `undefined`) {
      document.getElementById('video').playbackRate = 1.0;
      document.getElementById('video').play()
    }
     
  }

  const pauseVideo = () => {
    if (typeof window !== `undefined`) {
      document.getElementById('video').pause()
    }    
  }

  const rewindVideo = () => {
    if (typeof window !== `undefined`) {
      document.getElementById('video').currentTime = 0;
    }    
  }

  const fastPlayVideo = () => {
    if (typeof window !== `undefined`) {
      document.getElementById('video').playbackRate = 2.0;
    }    
  }

  const slowPlayVideo = () => {
    if (typeof window !== `undefined`) {
      document.getElementById('video').playbackRate = 0.5;
    }    
  }    

  return (
    <Fragment>
    <Layout>
      
      <Seo title="Home" />

      <FullScreenSection>

        <BackgroundImage
          Tag="section"
          className={'container-fluid fullheight-ribbon d-flex flex-column'}
          fluid={bg1}
          backgroundColor={`#040e18`}
        >

            <div className={`row flex-1`}>
              <div className={`col flex-1`}>

                <div className={`vr-controls`}>
                  <button type="button" onClick={playVideo} className={`btn btn-light btn-sm btn-pausar`}><i className={`fas fa-play`}></i></button>
                  <button type="button" onClick={pauseVideo} className={`btn btn-light btn-sm btn-pausar`}><i className={`fas fa-pause`}></i></button>
                  <button type="button" onClick={rewindVideo} className={`btn btn-light btn-sm btn-pausar`}><i className={`fas fa-step-backward`}></i></button>
                  <button type="button" onClick={fastPlayVideo} className={`btn btn-light btn-sm btn-pausar`}>2x</button>
                  <button type="button" onClick={slowPlayVideo} className={`btn btn-light btn-sm btn-pausar`}>1/2</button>
                </div>

                <AframeScene />

              </div>         
            </div>
            <div className={`row ribbon no-gutters`}>

            	<div className={`col-3 ribbon-blue`}></div>
            	<div className={`col-2 ribbon-red`}></div>
            	<div className={`col-4 ribbon-navy`}></div>
            	<div className={`col-3 ribbon-gray`}></div>

            </div>

        </BackgroundImage>

      </FullScreenSection>   

    </Layout>
    </Fragment>
  )

}

// Hook
function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
        };
        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

const StyledBackgroundSection = styled(VrPage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default VrPage
